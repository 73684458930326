<template>
    <!-- <button @click="test">test</button> -->
    <div class="actice-note">
        <p>יעדים פעילים נכון לתאריך: {{new Date().toLocaleDateString('he')}}</p>
    </div>
    <div class="loading-goal" v-if="loading_goal">
        <div class="loading-window">
            <p>טוען יעד, אנא המתן...</p>
            <p style="width:100%; text-align:center; font-size: 14px; color:var(--yellow);">זמן הטעינה תלוי בכמות העסקאות של הסוכן ומשך תקופת היעד</p>
        </div>
    </div>
    <h1 v-if="current_goals.length==0" style="color:#fff">אין יעדים לתצוגה</h1>
    <div class="current-goals">
        <template v-for="goal in current_goals" :key="goal.uid">
            <div class="goal-box">
                <div class="goal-title">{{goal.title}}</div>
                <div class="goal-end">
                    <div class="goal-type">{{goal.type}}</div>
                    <i class="material-icons" style="margin-left: 10px;">pending_actions</i>
                    <p>בתוקף עד: {{goal.date_end_js.toDate().toLocaleDateString('he')}}</p>
                </div>
                <div class="product-gallery">
                    <template v-for="item in goal.products" :key="item.makat">
                        <div class="item-image">
                            <img :src="item.image" alt="">
                        </div>
                    </template>
                </div>
                <div class="agent-goals">
                    <table id="table">
                        <tr>
                            <th>מס</th>
                            <th>סוכן</th>
                            <th>ביצוע</th>
                            <th>יעד</th>
                            <th>הפרש</th>
                        </tr>
                            <template v-for="agent in goal.agents" :key="agent.id">
                                <tr v-if="agent.goal > 0">
                                    <td>{{agent.id}}</td>
                                    <td>{{agent.name}}</td>
                                    <td v-if="goal.type == 'כמותי'">{{(agent.amount / Number(goal.products[0].boxSize)).toFixed(0)}}</td>
                                    <td v-if="goal.type == 'פיזור'">{{agent.amount}}</td>
                                    <td v-if="goal.type == 'כספי'">{{agent.amount.toFixed(2)}}</td>
                                    <td>{{agent.goal}}</td>
                                    <td v-if="goal.type == 'כמותי'" :class="check_margin(goal.type,agent.amount, agent.goal, goal.products[0].boxSize)">
                                        <p>{{((agent.amount / Number(goal.products[0].boxSize)) - agent.goal).toFixed(0)}}</p>
                                    </td>
                                    <td v-if="goal.type == 'פיזור'" :class="check_margin(goal.type,agent.amount, agent.goal)">
                                        <p>{{agent.amount - agent.goal}}</p>
                                    </td>
                                    <td v-if="goal.type == 'כספי'" :class="check_margin(goal.type,agent.amount, agent.goal)">
                                        <p>{{(agent.amount - agent.goal).toFixed(2)}}</p>
                                    </td>
                                </tr>
                            </template>
                            <tr style="color:black; font-size:18px;">
                                <td></td>
                                <td>סיכום</td>
                                <td>{{get_goal_meta(goal)[0].toFixed(0)}}</td>
                                <td>{{get_goal_meta(goal)[1]}}</td>
                                <td :style="check_diff(get_goal_meta(goal)[2])">{{(get_goal_meta(goal)[2]).toFixed(0)}}</td>
                            </tr>
                    </table>
                </div>
                <div class="goal-summery">
                    <el-button type="success" class="goal-btn" @click="update_results(goal)">טען נתונים</el-button>
                    <el-button type="warning" class="goal-btn" @click="edit_goal(goal)">ערוך יעד</el-button>
                    <el-button type="danger" class="goal-btn" @click="delete_goal(goal)">מחק יעד</el-button>
                </div>
                <div class="last-update">
                    <p>עדכון אחרון</p>
                    <p v-if="goal.fetched && goal.fetched.seconds">{{goal.fetched.toDate().toLocaleString('he')}}</p>
                    <p v-else> טרם עודכן</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { FireStore, projectFirestore, projectFunctions } from '../../../firebase/config';
import { onUnmounted } from '@vue/runtime-core';
import Swal from 'sweetalert2';
import { slide_pop_successs } from '../../../Methods/Msgs';

export default {
emits:["edit"],
setup(props,{emit}){
    const current_goals = ref([]);
    const timestamp = FireStore.Timestamp.fromDate(new Date(Date.now()))
    const loading_goal = ref(false);

    const test = async()=>{
        await projectFunctions.httpsCallable('Goals')()
    }
    const get_goals = projectFirestore.collection('Goals').where('date_end_js', '>', timestamp).onSnapshot(snap => {
        snap.docChanges().forEach(change => {
            if(change.type == 'added'){
                current_goals.value.push(change.doc.data())
                current_goals.value[current_goals.value.length - 1].agents.sort((aa, bb) => {
                    return aa.id - bb.id
                })
            }
            if(change.type == 'modified'){
                let inx = current_goals.value.findIndex(goal => {
                    return goal.uid == change.doc.id
                })
                if(inx != -1){
                    current_goals.value[inx] = change.doc.data();
                }
            }
            if(change.type == 'removed'){
                let inx = current_goals.value.findIndex(goal => {
                    return goal.uid == change.doc.id
                })
                if(inx != -1){
                    current_goals.value.splice(inx, 1);
                }
            }
        })
    })

    const edit_goal = (e) => {
        emit("edit", e)
    }

    const delete_goal = async (e) => {
        Swal.fire({icon:'warning', title: 'מחיקת יעד', text: 'האם אתה בטוח?', confirmButtonText: 'כן! מחק', cancelButtonText: 'ביטול', showCancelButton:true})
        .then(async res => {
            if(res.isConfirmed){
                await projectFirestore.collection('Goals').doc(e.uid).delete();
                slide_pop_successs("היעד נמחק בהצלחה!")
            }
        })
    }

    const update_results = async (goal) => {
        if(goal.type == 'כמותי'){
            loading_goal.value = true;
            let goal_makats = [];
            for(let k =0; k < goal.products.length; k++){
                goal_makats.push(goal.products[k].makat)
            }

            for(let i =0; i< goal.agents.length; i++){
                let agent = goal.agents[i]
                agent.amount = 0;
                const sales = await (await projectFunctions.httpsCallable('get_agent_sales',{timeout: 540000})({date_start: goal.date_start, agent_number: agent.id, makats: goal_makats})).data;
                for(let j = 0; j < sales.length; j++){
                    let sale = sales[j]
                    sale.items.forEach(item => {
                        if(goal_makats.includes(item.makat)){
                            agent.amount += item.amount
                        }
                    })
                }
            }
            console.log('Done!');
            loading_goal.value = false;
            goal.fetched = new Date();
            projectFirestore.collection('Goals').doc(goal.uid).set(goal);
        }

        if(goal.type == 'פיזור'){
            loading_goal.value = true;
            let goal_makats = [];
            for(let k =0; k < goal.products.length; k++){
                goal_makats.push(goal.products[k].makat)
            }
            for(let i =0; i< goal.agents.length; i++){
                let clients = [];
                let agent = goal.agents[i]
                agent.amount = 0;
                const sales = await (await projectFunctions.httpsCallable('get_agent_sales',{timeout: 540000})({date_start: goal.date_start, agent_number: agent.id, makats: goal_makats})).data;
                for(let j = 0; j < sales.length; j++){
                    let sale = sales[j]
                    if(!clients.includes(sale.client)){
                        clients.push(sale.client)
                        agent.amount += 1;
                    }
                }
            }
            console.log('Done!');
            loading_goal.value = false;
            goal.fetched = new Date();
            projectFirestore.collection('Goals').doc(goal.uid).set(goal);
        }

        if(goal.type == 'כספי'){
            loading_goal.value = true;
            let goal_makats = [];
            for(let k =0; k < goal.products.length; k++){
                goal_makats.push(goal.products[k].makat)
            }
            for(let i =0; i< goal.agents.length; i++){
                let agent = goal.agents[i]
                agent.amount = 0;
                const sales = await (await projectFunctions.httpsCallable('get_agent_sales',{timeout: 540000})({date_start: goal.date_start, agent_number: agent.id, makats: goal_makats})).data;
                console.log(sales);
                for(let j = 0; j < sales.length; j++){
                    let sale = sales[j]
                    sale.items.forEach(item => {
                        if(goal_makats.includes(item.makat)){
                            agent.amount += item.total_cost
                        }
                    })
                }
            }
            console.log('Done!');
            loading_goal.value = false;
            goal.fetched = new Date();
            projectFirestore.collection('Goals').doc(goal.uid).set(goal);
        }

    }

    const check_margin = (type, amount, agent_goal, boxSize) => {
        if(type == 'כמותי'){
            let res = (amount / Number(boxSize)) - agent_goal
            if(res > 0){
                return 'positive-margin'
            }else{
                return 'negative-margin'
            }
        }
        if(type == 'פיזור' || type == 'כספי'){
            let res = amount - agent_goal
            if(res > 0){
                return 'positive-margin'
            }else{
                return 'negative-margin'
            }
        }
    }

    const get_goal_meta = (goal) => {
        let total = 0;
        let total_goal = 0;
        if(goal.agents){
            goal.agents.forEach(agent => {
                total += Number(agent.amount)
                total_goal += Number(agent.goal)
            })
        }
        if(goal.type == 'כמותי'){
            total = total / Number(goal.products[0].boxSize)
        }
        let diff = total_goal - total
        return [total, total_goal, diff]
    }

    const check_diff = (diff) => {
        if(diff < 0){
            return "color: black;"
        }else{
            return 'color: var(--danger);'
        }
    }

    onUnmounted(() => {
        get_goals();
    })

    return{
        current_goals, edit_goal, delete_goal, update_results, loading_goal,
        check_margin, get_goal_meta, check_diff,test
    }
}
}
</script>

<style scoped>
.current-goals{
    width: 100%;
    height: calc(100% - 25px);
    display: grid;
    grid-auto-rows: 590px;
    grid-template-columns: repeat(3, minmax(375px, 1fr));
    overflow-y: auto;
    justify-content: space-between;
    gap: 5px;
    margin-top: 5px;
}
.actice-note{
    width: 100%;
    height: 25px;
    font-size: 18px;
    color: white;
    background: var(--success);
    text-align: center;
    text-shadow: 0 0 3px black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.goal-box{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.goal-title{
    width: 100%;
    background: var(--purple);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.goal-end{
    position: relative;
    width: 100%;
    background: var(--alert-purple);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 18px;
    padding: 0 5px 0 0;
}
.product-gallery{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    overflow-x: auto;
    padding: 3px;
    background: var(--alert-purple);
}
.item-image{
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.item-image img{
    max-width: 100%;
    max-height: 100%;
}
.goal-type{
    position: absolute;
    top: 5px;
    left: 5px;
    width: fit-content;
    padding: 0 5px 0 5px;
    background: var(--success);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    border-radius: 3px;
}
.agent-goals{
    width: 100%;
    height: calc(100% - 170px - 50px);
    overflow-y: auto;
}
.goal-summery{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 5px;
}
#table {
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}
#table tr:last-child{
    background: rgba(54, 250, 28, 0.726);
}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.goal-btn{
    margin: 0;
    margin-left: 5px;
}
.loading-goal{
    position: fixed;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.504);
    width: 100%;
    height: 100%;
    z-index: 2;
}
.loading-window{
    width: 370px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 24px;
    color: white;
    background: var(--main);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.positive-margin{
    color: green;
}
.positive-margin p{
    color: green;
}
.negative-margin{
    color: red;
}
.negative-margin p{
    color: red;
}
.last-update{
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 10px;
    color: var(--yellow);
    text-align: center;
}
@media only screen and (max-width: 800px) {
    .current-goals{
        grid-template-columns: 100%;
    }
}
</style>