<template>
    <div class="actice-note">
        <p>יעדים אינם פעילים נכון לתאריך: {{new Date().toLocaleDateString('he')}}</p>
    </div>
    <div class="current-goals">
        <template v-for="goal in current_goals" :key="goal.uid">
            <div class="goal-box">
                <div class="goal-title">{{goal.title}}</div>
                <div class="goal-end">
                    <div class="goal-type">{{goal.type}}</div>
                    <i class="material-icons" style="margin-left: 10px;">pending_actions</i>
                    <p>בתוקף עד: {{goal.date_end_js.toDate().toLocaleDateString('he')}}</p>
                </div>
                <div class="product-gallery">
                    <template v-for="item in goal.products" :key="item.makat">
                        <div class="item-image">
                            <img :src="item.image" alt="">
                        </div>
                    </template>
                </div>
                <div class="agent-goals">
                    <table id="table">
                        <tr>
                            <th>מס</th>
                            <th>סוכן</th>
                            <th>יעד</th>
                            <th>ביצוע</th>
                            <th>הפרש</th>
                        </tr>
                            <template v-for="agent in goal.agents" :key="agent.id">
                                <tr>
                                    <td>{{agent.id}}</td>
                                    <td>{{agent.name}}</td>
                                    <td>{{agent.goal}}</td>
                                    <td v-if="goal.type != 'כמותי'">{{agent.amount}}</td>
                                    <td v-else>{{(agent.amount / Number(goal.products[0].boxSize)).toFixed(0)}}</td>
                                    <td>0</td>
                                </tr>
                            </template>
                    </table>
                </div>
                <div class="goal-summery">
                    <el-button type="success" class="goal-btn">טען נתונים</el-button>
                    <el-button type="warning" class="goal-btn" @click="edit_goal(goal)">ערוך יעד</el-button>
                    <el-button type="danger" class="goal-btn" @click="delete_goal(goal)">מחק יעד</el-button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { FireStore, projectFirestore } from '../../../firebase/config';
import { onUnmounted } from '@vue/runtime-core';
import Swal from 'sweetalert2';
import { slide_pop_successs } from '../../../Methods/Msgs';

export default {
emits:["edit"],
setup(props,{emit}){
    const current_goals = ref([]);
    const timestamp = FireStore.Timestamp.fromDate(new Date(Date.now()))

    const get_goals = projectFirestore.collection('Goals').where('date_end_js', '<', timestamp).onSnapshot(snap => {
        snap.docChanges().forEach(change => {
            if(change.type == 'added'){
                current_goals.value.push(change.doc.data())
            }
            if(change.type == 'modified'){
                let inx = current_goals.value.findIndex(goal => {
                    return goal.uid == change.doc.id
                })
                if(inx != -1){
                    current_goals.value[inx] - change.doc.data();
                }
            }
            if(change.type == 'removed'){
                let inx = current_goals.value.findIndex(goal => {
                    return goal.uid == change.doc.id
                })
                if(inx != -1){
                    current_goals.value.splice(inx, 1);
                }
            }
        })
    })

    const edit_goal = (e) => {
        emit("edit", e)
    }

    const delete_goal = async (e) => {
        Swal.fire({icon:'warning', title: 'מחיקת יעד', text: 'האם אתה בטוח?', confirmButtonText: 'כן! מחק', cancelButtonText: 'ביטול', showCancelButton:true})
        .then(async res => {
            if(res.isConfirmed){
                await projectFirestore.collection('Goals').doc(e.uid).delete();
                slide_pop_successs("היעד נמחק בהצלחה!")
            }
        })
    }

    onUnmounted(() => {
        get_goals();
    })

    return{
        current_goals, edit_goal, delete_goal
    }
}
}
</script>

<style scoped>
.current-goals{
    width: 100%;
    height: calc(100% - 25px);
    display: grid;
    grid-auto-rows: 670px;
    grid-template-columns: repeat(3, minmax(375px, 1fr));
    overflow-y: auto;
    justify-content: space-between;
    gap: 5px;
    margin-top: 5px;
}
.actice-note{
    width: 100%;
    height: 25px;
    font-size: 18px;
    color: white;
    background: var(--danger);
    text-align: center;
    text-shadow: 0 0 3px black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.goal-box{
    width: 100%;
    height: 100%;
    background: var(--secondary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.goal-title{
    width: 100%;
    background: var(--purple);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.goal-end{
    position: relative;
    width: 100%;
    background: var(--alert-purple);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    height: 35px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 18px;
    padding: 0 5px 0 0;
}
.product-gallery{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    overflow-x: auto;
    padding: 3px;
    background: var(--alert-purple);
}
.item-image{
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.item-image img{
    max-width: 100%;
    max-height: 100%;
}
.goal-type{
    position: absolute;
    top: 5px;
    left: 5px;
    width: fit-content;
    padding: 0 5px 0 5px;
    background: var(--success);
    color: white;
    text-shadow: 0 0 3px black;
    text-align: center;
    border-radius: 3px;
}
.agent-goals{
    width: 100%;
    height: calc(100% - 170px - 50px);
    overflow-y: auto;
}
.goal-summery{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 5px;
}
#table {
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.goal-btn{
    margin: 0;
    margin-left: 5px;
}
@media only screen and (max-width: 800px) {
    .current-goals{
        grid-template-columns: 100%;
    }
}
</style>