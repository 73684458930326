<template>
    <div class="stats" v-loading="loading">
        <div class="main-stats">
            <div class="stat-box">
                <p style="color:var(--yellow);">סה"כ יעדים</p>
                <p class="stat-data-p" style="color:var(--yellow);">{{goals.length}}</p>
            </div>
            <div class="stat-box">
                <p style="color:var(--success);">פעילים</p>
                <p style="color:var(--success);" class="stat-data-p">{{get_macro_stats()[3]}}</p>
            </div>
            <div class="stat-box">
                <p>סה"כ פיזור</p>
                <p class="stat-data-p">{{get_macro_stats()[0]}}</p>
            </div>
            <div class="stat-box">
                <p>סה"כ כמותי</p>
                <p class="stat-data-p">{{get_macro_stats()[1]}}</p>
            </div>
            <div class="stat-box">
                <p>סה"כ כספי</p>
                <p class="stat-data-p">{{get_macro_stats()[2]}}</p>
            </div>
        </div>
        <div class="strip">
            <div class="camuti-clock">
                <p>{{((get_macro_stats()[5] / get_macro_stats()[4]) * 100).toFixed(0)}}%</p>
                <p>ביצוע</p>
            </div>
            <div class="camuti-stats">
                <div class="sec-title">
                    <p>נתוני יעדים כמותיים</p>
                </div>
                <p>הנתונים אינם כוללים יעדים פעילים!</p>
                <p>סה"כ יעדים: {{get_macro_stats()[6]}}</p>
                <p>סה"כ יעד כמותי מצטבר: {{get_macro_stats()[4]}} מארזים</p>
                <p>סה"כ יעד כמותי ביצוע: {{get_macro_stats()[5].toFixed(0)}} מארזים</p>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { projectFirestore } from '../../../firebase/config';
import { slide_pop_successs } from '../../../Methods/Msgs';
export default {
setup(){
    const loading = ref(false);
    const goals = ref([]);

    const get_goals = async () => {
        loading.value = true
        let docs = await projectFirestore.collection('Goals').get();
        goals.value = docs.docs.map(doc => doc.data());
        console.log(goals.value);
        slide_pop_successs("היעדים נטענו בהצלחה!")
        loading.value = false
    }
    
    const get_macro_stats = () => {
        let pizur = 0;
        let camuti = 0;
        let caspi = 0;
        let active = 0;
        let camuti_goal = 0;
        let camuti_bizua = 0;
        let camuti_past = 0;
        goals.value.forEach(goal => {
            if(goal.date_end_js.toDate() >= new Date()){
                active++
            }
            if(goal.type == 'פיזור'){
                pizur++;
            }
            if(goal.type == 'כמותי'){
                camuti++
                if(goal.date_end_js.toDate() < new Date()){
                    camuti_past++
                }
                goal.agents.forEach(agent => {
                    if(Number(agent.goal > 0) && goal.date_end_js.toDate() < new Date()){
                        camuti_goal += Number(agent.goal);
                        camuti_bizua += (Number(agent.amount) / Number(goal.products[0].boxSize));
                    }
                })
            }
            if(goal.type == 'כספי'){
                caspi++
            }
        })
        return [pizur, camuti, caspi, active, camuti_goal, camuti_bizua, camuti_past]
    }

    onMounted(() => {
        get_goals();
    })

    return{
        loading, goals, get_macro_stats
    }
}
}
</script>

<style scoped>
.stats{
    width: 100%;
    height: calc(100% - 25px);
    overflow-y: auto;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}
.main-stats{
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    height: 150px;
    overflow-x: auto;
}
.stat-box{
    width: 120px;
    height: 100%;
    background: var(--secondary);
    border-radius: 10px;
    margin-left: 5px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 2px black;
    gap: 5px;
}
.stat-data-p{
    width: fit-content;
    padding: 5px;
    background: var(--main);
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.strip{
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    margin-top: 5px;
    color: whitesmoke;
    overflow: hidden;
}
.camuti-clock{
    width: 160px;
    height: 100%;
    border: 2px solid var(--purple);
    margin-left: 5px;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
}
.camuti-stats{
    width: fit-content;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    height: 100%;
    color: whitesmoke;
    overflow: hidden;
}
.sec-title{
    width: 360px;
    height: fit-content;
    padding: 5px 0 5px 0;
    background: var(--purple);
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.778);
    font-size: 20px;
    margin-bottom: 5px;
    flex-shrink: 0;
    text-align: center;
    border-radius: 5px;
    
}
</style>