<template>
    <div class="new-goal">
        <p class="input-label">רשום שם ליעד</p>
        <el-input placeholder="כותרת \ שם היעד" v-model="new_goal.title"></el-input>
        <p class="input-label">בחר את סוג היעד</p>
        <div class="goal-types">
            <div class="goal-type-btn" @click="new_goal.type = 'כמותי'">
                <i class="material-icons" v-if="new_goal.type == 'כמותי'">check_circle</i>
                <p>כמותי</p>
            </div>
            <div class="goal-type-btn" @click="new_goal.type = 'פיזור'">
                <i class="material-icons" v-if="new_goal.type == 'פיזור'">check_circle</i>
                <p>פיזור</p>
            </div>
            <div class="goal-type-btn" @click="new_goal.type = 'כספי'">
                <i class="material-icons" v-if="new_goal.type == 'כספי'">check_circle</i>
                <p>כספי</p>
            </div>
        </div>
        <p class="input-label">כיצד לשייך פריטים?</p>
        <div class="goal-types">
            <div class="goal-type-btn" style="width:48%;" @click="new_goal.makat_type = 'מקט'">
                <i class="material-icons" v-if="new_goal.makat_type == 'מקט'">check_circle</i>
                <p>מקטים נפרדים</p>
            </div>
            <div class="goal-type-btn" style="width:48%;" @click="new_goal.makat_type = 'מרכז'">
                <i class="material-icons" v-if="new_goal.makat_type == 'מרכז'">check_circle</i>
                <p>מקט מרכז</p>
            </div>
        </div>
        <p class="input-label" v-if="new_goal.makat_type == 'מקט'">
            הכנס מקטים מופרדים ברווחים
        </p>
        <p class="input-label" v-if="new_goal.makat_type == 'מרכז'">
            רשום מספר מקט מרכז
        </p>
        <el-input placeholder="רשום מקט" v-model="temp_makats" v-if="new_goal.makat_type"></el-input>
        <el-button type="primary" class="goal-btn" v-if="temp_makats" @click="check_makats">בדוק מקט / מקטים</el-button>
        <template v-for="item in new_goal.products" :key="item.makat">
            <p style="color:var(--success)">{{item.makat}}  {{item.name}}</p>
        </template>
        <template v-for="agent in new_goal.agents" :key="agent.uid">
            <div class="agent-box" v-if="new_goal.products.length > 0">
                <div class="agent-image">
                    <img :src="agent.image" alt="">
                </div>
                <div class="agent-info">
                    <p>{{agent.name}}</p>
                    <el-input placeholder="רשום יעד" v-model="agent.goal" type="number"/>
                </div>
            </div>
        </template>
        
        <p class="input-label" v-if="new_goal.products.length > 0">תאריך תחילת היעד</p>
        <input type="date" name="" id="" v-model="new_goal.date_start" class="goal-date" v-if="new_goal.products.length > 0">
        {{new_goal.date_start}}
        <p class="input-label" v-if="new_goal.products.length > 0">תאריך סיום היעד</p>
        <input type="date" name="" id="" v-model="new_goal.date_end" class="goal-date" v-if="new_goal.products.length > 0">

        <el-button type="success" class="goal-btn" @click="finish_goal">סים יעד חדש</el-button>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../firebase/config';
import Swal from 'sweetalert2';
import { slide_pop_successs,slide_pop_error } from '../../../Methods/Msgs';
import { calculateDateDifference } from '../../../Methods/Dates';
export default {
props:["new_goal"],
emits:["close"],
setup(props, {emit}){
    const temp_makats = ref('');
    const agents = ref([])

    const check_makats = async () => {
        if(temp_makats.value.length > 0){
            props.new_goal.products = [];

            if(props.new_goal.makat_type == 'מקט'){
                let makats = temp_makats.value.split(' ')

                for(let i =0; i< makats.length; i++){
                    const doc = await projectFirestore.collection('Products').doc(makats[i]).get();
                    if(!doc.exists){
                        Swal.fire({icon:"error", title: 'שגיאה', text: `המקט ${makats[i]} איננו נמצא במערכת`})
                        return
                    }else{
                        let product = doc.data();
                        props.new_goal.products.push({
                            name: product.name,
                            makat: product.makat,
                            boxSize: product.boxSize,
                            image: product.image
                        })
                    }
                }
            }else{
                const docs = await projectFirestore.collection('Products').where('paritMerakez', '==', temp_makats.value).get();
                if(docs.empty){
                    Swal.fire({icon:"error", title: 'שגיאה', text: `מקט מרכז ${temp_makats.value} איננו נמצא במערכת`})
                    return
                }else{
                    let products = docs.docs.map(doc=> doc.data());
                    products.forEach(product => {
                        props.new_goal.products.push({
                            name: product.name,
                            makat: product.makat,
                            boxSize: product.boxSize,
                            image: product.image
                        })
                    })
                }
            }
        }else{
             Swal.fire({icon:"error", title: 'שגיאה', text: `נא למלא מספרי מקטים`})
        }
    }

    const get_agents = async () => {
        const docs = await projectFirestore.collection('Users').where('agent', '>', '0').get()
        if(!docs.empty){
            props.new_goal.agents = [];
            agents.value = docs.docs.map(doc => doc.data());
            agents.value.forEach(agnt => {
                props.new_goal.agents.push({
                    id: agnt.agent,
                    uid: agnt.uid,
                    name: agnt.name,
                    goal: 0,
                    amount: 0,
                    image: agnt.profile_image
                })
            })
        }
    }
    if(agents.value.length == 0 && props.new_goal.agents.length == 0){
        get_agents();
    }

    const finish_goal = async () => {
        try{
            if(props.new_goal.title.length < 3){
                Swal.fire({icon:"error", title: 'שגיאה', text: `נא למלא שם ליעד`})
                return
            }
            if(props.new_goal.type < 2){
                Swal.fire({icon:"error", title: 'שגיאה', text: `נא לבחור את סוג היעד`})
                return
            }
            if(props.new_goal.makat_type < 2){
                Swal.fire({icon:"error", title: 'שגיאה', text: `נא לבחור כיצד לשייך מקטים ליעד`})
                return
            }
            if(props.new_goal.products.length == 0){
                Swal.fire({icon:"error", title: 'שגיאה', text: `נא לשייך מקטים או מקט מרכז ליעד`})
                return
            }
            if(props.new_goal.date_start.length < 5){
                Swal.fire({icon:"error", title: 'שגיאה', text: `נא לבחור תאריך התחלה ליעד`})
                return
            }
            if(props.new_goal.date_end.length < 5){
                Swal.fire({icon:"error", title: 'שגיאה', text: `נא לבחור תאריך סיום ליעד`})
                return
            }
            
            if(calculateDateDifference(props.new_goal.date_start,props.new_goal.date_end) > 90){
                Swal.fire({icon:"error", title: 'שגיאה', text: `עליך להגדיר תאריך התחלה וסיום עד 3 חודשים!`})
                return 
            }
            props.new_goal.date_start_js = new Date(props.new_goal.date_start);
            props.new_goal.date_end_js = new Date(props.new_goal.date_end);
            
            if(!props.new_goal.uid){
                const doc = projectFirestore.collection('Goals').doc()
                props.new_goal.uid = doc.id;
                props.new_goal.created = new Date;
                await doc.set(props.new_goal)
                slide_pop_successs("היעד הוקם בהצלחה!");
                emit("close");
            }else{
                props.new_goal.edited = new Date();
                await projectFirestore.collection('Goals').doc(props.new_goal.uid).update(props.new_goal)
                slide_pop_successs("היעד עודכן בהצלחה!");
                emit("close");
            }
        }catch(err){
            Swal.fire({icon:"error", title: 'שגיאה', text: err.message})
        }

    }

    return{
        temp_makats, check_makats, agents, finish_goal
    }
}
}
</script>

<style scoped>
.new-goal{
    width: 100%;
    max-width: 375px;
    height: fit-content;
}
.input-label{
    color: white;
    padding: 5px 0 5px 0;
    font-size: 18px;
}
.goal-types{
    width: 100%;
    padding: 5px 0 5px 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.goal-type-btn{
    width: 31%;
    height: 100%;
    background: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.goal-type-btn i {
    color: var(--success);
    margin-left: 5px;
}
.goal-btn{
    width: 100%;
    margin: 10px 0 10px 0;
}
.agent-box{
    width: 100%;
    height: 80px;
    background: var(--secondary);
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.agent-image{
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.agent-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.agent-info{
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: white;
    padding: 5px;
}
.goal-date{
    width: 100%;
    height: 40px;
    font-size: 24px;
    margin-bottom: 5px;
}
</style>